import { FsIcon, IconSetType } from '@printi/ds-offset-react-core';
import * as S from './ShortcutIcon.styles';

interface ShortcutIconProps {
  icon: IconSetType;
  label: string;
  onClick?: () => void;
}

export const ShortcutIcon = ({ icon, label, onClick }: ShortcutIconProps) => {
  return (
    <S.Container>
      <S.Content aria-disabled="false" onClick={onClick}>
        <FsIcon icon={icon} />
      </S.Content>
      <span>{label}</span>
    </S.Container>
  );
};
