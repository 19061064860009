import { api } from '@mf/common/config/axios/serviceApi';
import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';
import { TAxiosDefaultConfig } from '../../defaults/types';
import {
  TGetProductCategories,
  TGetProductCategory,
  TProductCategory,
} from './types';

const strapiProductCategory = ({ baseUrl, token }: TAxiosDefaultConfig) => {
  const apiCms: AxiosInstance = api({ baseUrl, token });

  const getProductCategories = async (): Promise<TProductCategory[]> => {
    const { data }: TGetProductCategories = await apiCms.get(
      '/v1/product-categories',
    );

    return data?.data;
  };

  const getProductCategory = async ({
    slug,
  }: {
    slug: string;
  }): Promise<TProductCategory> => {
    const { data }: TGetProductCategory = await apiCms.get(
      `/v1/product-categories?slug=${slug}`,
    );

    return data?.data[0];
  };

  const useProductCategory = (slug: string) =>
    useQuery([`product-category-${slug}`], () => getProductCategory({ slug }));

  const useProductCategories = () => {
    return useQuery(['product-categories'], () => getProductCategories());
  };

  return {
    getProductCategories,
    getProductCategory,
    useProductCategory,
    useProductCategories,
  };
};

export default strapiProductCategory;
