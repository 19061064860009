import styled from 'styled-components';

export const Main = styled.main`
  width: 100%;
`;

export const Container = styled.div`
  max-width: var(--container-max-width);
  margin: 0 auto;
  @media (max-width: 1480px) {
    padding: 0 var(--spacing-5x);
  }
  @media (max-width: 1064px) {
    padding: 0 var(--spacing-3x);
  }
  @media (max-width: 768px) {
    padding: 0 var(--spacing-2x);
  }
`;

export const Section = styled.section`
  margin-bottom: var(--spacing-6x);
  &:first-child {
    margin-bottom: var(--spacing-2x);
  }
  &:last-child {
    margin-bottom: 0;
  }
`;
