'use client';

import React, { useState } from 'react';
import { useSession } from 'next-auth/react';
import { useLogManager } from '@mf/common/components/Logs/LogManager.hooks';
import { CustomSession } from '@mf/common/config/nextAuth/types';
import { useCartStore } from '@mf/common/store/cart';
import styled from 'styled-components';
import { ITopicIcon, TopicIconsGroup } from '@/components/main/TopicIconsGroup';
import { FirstPurchaseModal } from '@/components/main/TopicIconsGroup/Modals/FirstPurchaseModal';

const Container = styled.div`
  width: 100%;
  margin-bottom: var(--spacing-6x);
`;

export function HomeV2TopicIconsGroup() {
  const { data: session } = useSession();
  const { trackEvent } = useLogManager();
  const { customerId } = useCartStore();
  const userSession = session as CustomSession;

  const [isFirstPurchaseModalOpen, setIsFirstPurchaseModalOpen] =
    useState(false);

  const handleFirstPurchaseModal = (state: boolean) => {
    setIsFirstPurchaseModalOpen(state);
    const event = state ? 'view_modal' : 'close_modal';

    trackEvent(event, {
      modal_name: 'Primeira Compra',
      modal_id: 'modal_primeira_compra',
      session_id: userSession,
      customer_id: customerId,
    });
  };

  const topicIcons = [
    {
      icon: 'CheckoutLine',
      heading: 'Primeira compra?',
      paragraph: 'Tem cupom para você! Confira',
      onClick: () => handleFirstPurchaseModal(true),
      topic_icon_id: 1,
    },
    {
      icon: 'CreditCardLine',
      heading: 'Parcele em até 12x',
      paragraph: 'Nas compras acima de R$1000',
      topic_icon_id: 2,
    },
    {
      icon: 'StickerLine',
      heading: 'Garantia de satisfação',
      paragraph: 'Reimpressão grátis se precisar',
      topic_icon_id: 3,
    },
    {
      icon: 'WhatsappLine',
      heading: 'Precisa de ajuda?',
      paragraph: 'Fale com a gente pelo Whatsapp',
      url: 'https://api.whatsapp.com/send?phone=551141183816',
      target: '_blank',
      topic_icon_id: 4,
    },
  ] satisfies ITopicIcon[];

  return (
    <Container>
      <TopicIconsGroup topicIcons={topicIcons} />

      <FirstPurchaseModal
        isOpen={isFirstPurchaseModalOpen}
        onClose={() => handleFirstPurchaseModal(false)}
      />
    </Container>
  );
}
