import {
  ButtonSecondarySize,
  DescriptionTag,
  FsButtonSecondary,
  FsDescription,
  FsHeading,
  FsParagraph,
  FsTopicIcon,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import Image from 'next/image';
import * as S from './QualityPrinti.styles';

export const QualityPrinti = () => {
  return (
    <S.About>
      <S.Intro>
        <S.Title>
          <FsDescription tag={DescriptionTag.H2}>
            Qualidade Printi
          </FsDescription>
          <S.HeadingWrapper>
            <FsHeading tag={HeadingTag.H3} size={HeadingSize.MD}>
              Processos e muita tecnologia
            </FsHeading>
          </S.HeadingWrapper>
        </S.Title>
        <S.Description>
          <FsParagraph>
            Acreditamos que você não precisa abrir mão da qualidade em nome do
            preço ou prazo, temos processos e muita tecnologia para garantir uma
            impressão tranquila. Mas, se ainda assim acontecer um imprevisto,
            conte com nosso novo suporte por{' '}
            <S.HLink
              href="https://api.whatsapp.com/send?phone=551141183816"
              target="_blank"
            >
              Whatsapp
            </S.HLink>
            .
          </FsParagraph>
        </S.Description>
      </S.Intro>

      <S.ImageWrapper>
        <S.Image>
          <Image
            src="https://d1br4h274rc9sc.cloudfront.net/content/quality_printi_e03ec6e952.webp"
            alt="Printi - Qualidade"
            width={1300}
            height={800}
          />
        </S.Image>
      </S.ImageWrapper>
      <S.Bottom>
        <S.Advantages>
          <FsTopicIcon
            icon="TechnologyLine"
            paragraph="Maquinário de ponta para pequenas e médias tiragens."
            headingProps={{
              children: 'Tecnologia',
              tag: HeadingTag.H3,
              size: HeadingSize.XS,
            }}
          />
          <FsTopicIcon
            icon="TimerLine"
            paragraph="Evoluímos nossa política e, claro, os nossos valores de frete."
            headingProps={{
              children: 'Melhores prazos',
              tag: HeadingTag.H3,
              size: HeadingSize.XS,
            }}
          />
          <FsTopicIcon
            icon="BoxLine"
            paragraph="Imprimimos e reenviamos em caso de erro na impressão."
            headingProps={{
              children: 'Reimpressão grátis',
              tag: HeadingTag.H3,
              size: HeadingSize.XS,
            }}
          />
          <FsTopicIcon
            icon="WhatsappLine"
            paragraph={
              <>
                Das 08 às 18h, de segunda à sexta-feira por{' '}
                <a
                  href="https://api.whatsapp.com/send?phone=551141183816"
                  target="_blank"
                  style={{
                    textDecoration: 'underline',
                  }}
                >
                  Whatsapp
                </a>
                .
              </>
            }
            headingProps={{
              children: 'Suporte por Whatsapp',
              tag: HeadingTag.H3,
              size: HeadingSize.XS,
            }}
          />
        </S.Advantages>
        <S.CTAContainer>
          <FsButtonSecondary
            // @ts-expect-error - as prop is not recognized
            as="a"
            href="/institucional/"
            size={ButtonSecondarySize.LG}
          >
            Sobre a Printi
          </FsButtonSecondary>
        </S.CTAContainer>
      </S.Bottom>
    </S.About>
  );
};
