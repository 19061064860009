'use client';

import {
  ButtonSecondarySize,
  ChipSelectSize,
  FsButtonSecondary,
  FsChipSelect,
  FsTopSection,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import Link from 'next/link';
import { useLayout } from '@mf/common/components/Media/Layout';
import { useRouter } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import useDynamicMargins from '@mf/common/utils/hooks/useDynamicMargins/index';
import { CardProduct, CardProductSkeleton } from '@mf/common/components/index';
import { DropdownOrder, useProductsHooks } from '@/screens/main/Products';
import strapiClassificationOption from '@/repositories/aecomStrapiCms/classificationOption';
import strapiProduct from '@/repositories/aecomStrapiCms/product';
// eslint-disable-next-line import/order
import * as S from './BusinessProducts.styles';

export function BusinessProducts() {
  const auth = {
    baseUrl: process.env.BFF_API_BASE_URL,
  };

  const { useClassificationOptions } = strapiClassificationOption(auth);
  const { useProductsListing } = strapiProduct(auth);

  const { isMobile } = useLayout();
  const { push } = useRouter();
  const productsRef = useRef<HTMLDivElement>(null);
  const chipsRef = useRef<HTMLDivElement>(null);
  const [selectedChip, setSelectedChip] = useState('');
  const { groupFilters, getFiltersToQuery, getSortToQuery } =
    useProductsHooks();
  const { data: options } = useClassificationOptions();
  const heading = 'Para o seu negócio';

  const handleChipClick = (chip: string) => {
    setSelectedChip(chip);
  };

  const [productsMarginRight, productsMarginLeft] = useDynamicMargins(
    productsRef,
    isMobile,
  );
  const [chipsMarginRight, chipsMarginLeft] = useDynamicMargins(
    chipsRef,
    isMobile,
  );

  const optionsClassification = () => {
    return Object.keys(groupFilters(options || [])).includes('Segmentos')
      ? groupFilters(options || [])['Segmentos']
      : [];
  };

  const { data: products } = useProductsListing({
    params: {
      pagination: { page: 0, pageSize: 8 },
      filters: {
        classification_options: {
          slug: {
            $contains: getFiltersToQuery({ [selectedChip]: true }),
          },
        },
      },
      sort: [getSortToQuery(DropdownOrder.BestSellers)],
      populate: ['banner_image', 'small_image', 'small_image_hover'],
    },
  });

  useEffect(() => {
    const element = document.getElementById('BusinessProducts');
    const observer = new IntersectionObserver((element) => {
      if (element[0].isIntersecting) {
        const itemsMap = products?.pages[0]?.data.map((item) => ({
          item_id: item.attributes.final_product_id,
          item_name: item.attributes.name,
          affiliation: 'Printi',
          discount: 0,
          index: 0,
          item_brand: 'Printi',
          item_list_id: heading && heading.toLowerCase().replace(' ', '_'),
          item_list_name: heading,
          price: item?.attributes?.minimal_price?.toString() || '0',
          quantity: item.attributes.minimal_quantity,
        }));

        if (products) {
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            event: 'view_item_list',
            event_name: 'view_item_list',
            item_list_id: heading && heading.toLowerCase().replace(' ', '_'),
            item_list_name: heading,
            ecommerce: {
              currency: 'BRL',
              items: itemsMap,
            },
          });
        }
      }
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [products, heading]);

  return (
    <S.BusinessProducts id="BusinessProducts">
      <div>
        <FsTopSection
          heading={heading}
          headingProps={{ tag: HeadingTag.H2 }}
          description=""
        />
        <S.ChipContainer
          ref={chipsRef}
          style={{
            marginRight: `${chipsMarginRight}`,
            marginLeft: `${chipsMarginLeft}`,
          }}
        >
          <FsChipSelect
            size={ChipSelectSize.SM}
            selected={selectedChip === ''}
            inputProps={{ onChange: () => handleChipClick('') }}
            onClick={(e) => {
              if (selectedChip === '') {
                e.preventDefault();
              }
              window.dataLayer.push({
                event: 'genericEvent',
                event_name: 'click_business_suggestion',
                event_parameter_1: 'Todos',
              });
            }}
          >
            Todos
          </FsChipSelect>
          {optionsClassification().map((option) => (
            <FsChipSelect
              key={option.id}
              size={ChipSelectSize.SM}
              selected={selectedChip === option.attributes.slug}
              inputProps={{
                onChange: () => handleChipClick(option.attributes.slug),
              }}
              onClick={(e) => {
                if (selectedChip === option.attributes.slug) {
                  e.preventDefault();
                }
                window.dataLayer.push({
                  event: 'genericEvent',
                  event_name: 'click_business_suggestion',
                  event_parameter_1: option.attributes.name,
                });
              }}
            >
              {option.attributes.name}
            </FsChipSelect>
          ))}
        </S.ChipContainer>
      </div>
      <S.ProductsGrid
        ref={productsRef}
        style={{
          marginRight: `${productsMarginRight}`,
          marginLeft: `${productsMarginLeft}`,
        }}
      >
        {!products &&
          Array.from({ length: 8 }).map((_, index) => (
            <S.CardProductWrapper key={index}>
              <CardProductSkeleton />
            </S.CardProductWrapper>
          ))}
        {products?.pages[0]?.data.map((product) => (
          <S.CardProductWrapper key={product.id}>
            <Link href={`/${product.attributes.slug}/`}>
              <CardProduct
                description="A partir de"
                product={product}
                carrousel={true}
              />
            </Link>
          </S.CardProductWrapper>
        ))}
      </S.ProductsGrid>
      <S.CTAContainer>
        <FsButtonSecondary
          size={ButtonSecondarySize.LG}
          onClick={() => push('/produtos')}
        >
          Ver todos
        </FsButtonSecondary>
      </S.CTAContainer>
    </S.BusinessProducts>
  );
}
