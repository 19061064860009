import {
  ButtonSize,
  FsButton,
  FsHeading,
  FsInputSelect,
  FsInputText,
  FsParagraph,
} from '@printi/ds-offset-react-core';
import styled from 'styled-components';
import Image from 'next/image';
import registerImage from '@/assets/register-and-enjoy.png';

const NewsletterSection = styled.section``;

const NewsletterGrid = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-8x);
  align-items: center;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: var(--spacing-4x);
  }
`;

const NewsletterForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4x);
`;

const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3x);
`;

const Button = styled(FsButton)`
  width: 140px;
`;

const PrivacyText = styled(FsParagraph)`
  color: #525252;
  font-size: 14px;
  line-height: 20px;

  a {
    text-decoration: underline;
  }
`;

export function HomeV2Newsletter() {
  return (
    <NewsletterSection>
      <NewsletterGrid>
        <div>
          <FsHeading>Cadastre-se e aproveite</FsHeading>
          <Image
            src={registerImage.src}
            alt="Printi - A sua gráfica, sempre"
            width={680}
            height={428}
            style={{
              maxWidth: '100%',
              height: 'auto',
              marginTop: 'var(--spacing-4x)',
            }}
          />
        </div>
        <NewsletterForm>
          <FormFields>
            <FsInputText
              label="E-mail"
              placeholder="Ex.: maria.silva@printi.com.br"
            />
            <FsInputSelect
              label="Para qual finalidade você busca nossos produtos?"
              placeholder="Selecione uma opção"
            >
              <option value="pessoal">Uso pessoal</option>
              <option value="empresa">Uso empresarial</option>
              <option value="revenda">Revenda</option>
            </FsInputSelect>
            <Button size={ButtonSize.SM}>Cadastrar</Button>
            <PrivacyText>
              A Printi precisa das informações de contato que você nos fornece
              para entrar em <br /> contato. Você pode deixar de receber essas
              comunicações quando quiser. Mais <br /> informações em{' '}
              <a href="/politica-de-privacidade">Política de Privacidade</a>.
            </PrivacyText>
          </FormFields>
        </NewsletterForm>
      </NewsletterGrid>
    </NewsletterSection>
  );
}
