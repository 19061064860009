import { api } from '@mf/common/config/axios/serviceApi';
import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';
import { TAxiosDefaultConfig } from '../../defaults/types';
import {
  TBannerHome,
  // TBannersHome,
  TGetBannerHome,
  TGetBannersHome,
} from './types';

const strapiBannersHome = ({ baseUrl, token }: TAxiosDefaultConfig) => {
  const apiBff: AxiosInstance = api({ baseUrl, token });

  const getBanners = async (): Promise<TGetBannersHome> => {
    const data: TGetBannersHome = await apiBff.get(`/v1/banners/home`);

    return data;
  };

  const getBannerByType = async ({
    type,
  }: {
    type: string;
  }): Promise<TBannerHome> => {
    const { data }: TGetBannerHome = await apiBff.get(
      `/v1/banners/home?tye=${type}`,
    );

    return data;
  };

  const useBannersHome = () =>
    useQuery({
      queryKey: ['bannersHome'],
      queryFn: getBanners,
      staleTime: 24 * 60 * 60 * 1000, // 24 horas
      cacheTime: 25 * 60 * 60 * 1000, // 25 horas
      refetchOnWindowFocus: false,
    });

  return {
    getBanners,
    getBannerByType,
    useBannersHome,
  };
};

export default strapiBannersHome;
