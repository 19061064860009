'use client';

import { Carousel } from '@mf/common/components/index';
import { useSearchParams } from 'next/navigation';
import { TShowcases } from '@/repositories/aecomStrapiCms/showcase/types';
import strapiBannersHome from '../../../../../packages/promotions/src/repositories/aecomStrapiCms/bannerHome';
import { HomeV2BannerHybrid } from './components/BannerHybrid';
import { HomeV2TopicIconsGroup } from './components/TopicIconsGroup';
import { HomeV2PopularCategories } from './components/PopularCategories';
import { HomeV2Newsletter } from './components/Newsletter';
import { HomeV2SEO } from './components/SEO';
import { Container, Main, Section } from './styles';

interface HomeScreenV2Props {
  homeShowcases: TShowcases[];
}

export function HomeScreenV2({ homeShowcases }: HomeScreenV2Props) {
  const searchParams = useSearchParams();
  const auth = {
    baseUrl: process.env.BFF_API_BASE_URL,
  };

  const { useBannersHome } = strapiBannersHome(auth);
  const { data: configBanner } = useBannersHome();

  const getHeroBannerAttributes = () => configBanner?.data?.hero?.attributes;
  const getHeroBannerId = () => configBanner?.data?.hero?.id ?? null;

  const getCommonBannerAttributes = () =>
    configBanner?.data?.common?.attributes;
  const getCommonBannerId = () => configBanner?.data?.common?.id ?? null;

  const showSEO = searchParams?.get('showSEO');
  const showNewsletter = searchParams?.get('showNewsletter');

  return (
    <Main>
      <Container>
        <Section className="hero-banner">
          <HomeV2BannerHybrid
            banner={getHeroBannerAttributes()}
            bannerId={getHeroBannerId()}
          />
        </Section>

        <Section className="topic-icons-group">
          <HomeV2TopicIconsGroup />
        </Section>

        <Section className="popular-categories">
          <HomeV2PopularCategories />
        </Section>

        {homeShowcases && homeShowcases.length > 0 ? (
          <Section className={homeShowcases[0]?.attributes?.slug || ''}>
            <Carousel
              heading={homeShowcases[0]?.attributes?.title || ''}
              cards={homeShowcases[0]?.attributes?.products?.data || []}
              loading="eager"
            />
          </Section>
        ) : null}

        <Section className="common-banner">
          <HomeV2BannerHybrid
            banner={getCommonBannerAttributes()}
            bannerId={getCommonBannerId()}
          />
        </Section>

        {homeShowcases?.slice(1)?.map((showcase) => (
          <Section key={showcase.id} className={showcase.attributes.slug || ''}>
            <Carousel
              heading={showcase.attributes.title || ''}
              cards={showcase.attributes.products?.data || []}
              loading="eager"
            />
          </Section>
        ))}

        {showNewsletter && (
          <Section className="newsletter">
            <HomeV2Newsletter />
          </Section>
        )}

        {showSEO && (
          <Section className="seo">
            <HomeV2SEO
              title="Contrary to popular belief"
              description='Contrary to popular belief, Lorem Ipsum is not simply random text. It
              has roots in a piece of classical Latin literature from 45 BC, making it
              over 2000 years old. Richard McClintock, a Latin professor at
              Hampden-Sydney College in Virginia, looked up one of the more obscure
              Latin words, consectetur, from a Lorem Ipsum passage, and going through
              the cites of the word in classical literature, discovered the
              undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33
              of "de Finibus Bonorum et Malorum" (The Extremes of Good and
              Evil) by Cicero, written in 45 BC. This book is a treatise on the theory
              of ethics, very popular during the Renaissance. The first line of Lorem
              Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in
              section 1.10.32.Contrary to popular belief, Lorem Ipsum is not simply
              random text. It has roots in a piece of classical Latin literature from
              45 BC, making it over 2000 years old. Richard McClintock, a Latin
              professor at Hampden-Sydney College in Virginia, looked up one of the
              more obscure Latin words, consectetur, from a Lorem Ipsum passage, and
              going the cites of the word in classical literature, discovered the
              undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33'
            />
          </Section>
        )}
      </Container>
    </Main>
  );
}
