import { media } from '@mf/common/utils/styles/index';
import styled from 'styled-components';
import { FsTopicIcon } from '@printi/ds-offset-react-core';
import Link from 'next/link';

export const About = styled.section`
  display: flex;
  padding: var(--spacing-none) 0px;
  flex-direction: column;
  align-items: center;

  ${media.lessThan('touch')} {
    display: flex;
    align-self: stretch;
    width: auto;
  }
`;

export const Intro = styled.div`
  display: flex;
  padding: 0 0 var(--spacing-5x) 0;
  align-items: flex-start;
  gap: var(--spacing-1x);
  align-self: stretch;

  ${media.lessThan('mobile')} {
    flex-direction: column;
    padding: 0 0 var(--spacing-8x) 0;

    gap: var(--spacing-4x);
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-1x);
  flex: 1 0 0;

  ${media.lessThan('touch')} {
    align-self: stretch;
  }
`;

export const HeadingWrapper = styled.div`
  max-width: 250px;
`;

export const Description = styled.div`
  width: 668px;
  align-self: flex-end;

  ${media.lessThan('touch')} {
    width: auto;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--spacing-3x);
  align-self: stretch;

  ${media.lessThan('touch')} {
    padding: 0;
    gap: 0;
  }
`;

export const Image = styled.div`
  display: flex;
  height: 800px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-1x);
  align-self: stretch;
  overflow: hidden;
  position: relative;

  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    aspect-ratio: 1 / 1;
  }

  ${media.lessThan('touch')} {
    height: 320px;
  }
`;

export const Caption = styled.div`
  margin-top: var(--spacing-1x);
  text-align: center;
`;

export const Bottom = styled.div`
  display: flex;
  padding: 40px 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;

  ${media.lessThan('touch')} {
    padding: var(--spacing-none);
  }
`;

export const Advantages = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: dense;
  width: 100%;

  ${media.lessThan('touch')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${media.lessThan('mobile')} {
    display: flex;
    flex-direction: column;
  }
`;

export const TopicIcon = styled(FsTopicIcon)`
  display: flex;
  padding: var(--spacing-10x) var(--spacing-5x);
  gap: var(--spacing-3x);
  flex: 1 0 0;
  width: auto;

  border-radius: var(0px);
  background: var(--surface-color-01);

  ${media.lessThan('touch')} {
    padding: var(--spacing-8x) var(--spacing-4x);
    align-self: stretch;
  }
`;

export const CTAContainer = styled.div`
  display: flex;
  padding: var(--spacing-5x);
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-4x);
  align-self: stretch;
`;

export const HLink = styled(Link)`
  text-decoration: underline;
`;
