import styled from 'styled-components';
import { media } from '@mf/common/utils/styles/index';

export const Container = styled.div`
  padding-bottom: var(--spacing-3x);
  width: 100%;
  overflow: hidden;

  ${media.lessThan('desktop')} {
    padding: 0;
    width: 100%;
  }
`;

export const TopicIconsContainer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;

  ${media.greaterThan('desktop')} {
    overflow: hidden;
  }

  ${media.lessThan('desktop')} {
    display: flex;
    padding: 0 var(--spacing-2x);
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    max-width: 100vw;
    padding-right: calc(var(--spacing-2x) + 16px);

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const TopicIconsWrapper = styled.div`
  display: flex;
  height: 100%;

  ${media.greaterThan('desktop')} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
  }

  ${media.lessThan('desktop')} {
    > div {
      scroll-snap-align: start;
      flex-shrink: 0;

      @media (min-width: 458px) {
        height: auto;
      }

      @media (min-width: 992px) {
        flex: 0 0 calc((100% - var(--spacing-6x)) / 3);
      }

      @media (min-width: 736px) and (max-width: 991px) {
        flex: 0 0 calc((100% - var(--spacing-6x)) / 2);
      }

      @media (max-width: 735px) {
        flex: 0 0 calc((100% - var(--spacing-4x)) / 2);
      }

      @media (max-width: 614px) {
        flex: 0 0 calc((100% - var(--spacing-4x)) / 2);
      }

      @media (max-width: 438px) {
        flex: 0 0 calc(100% - var(--spacing-4x));
        min-width: auto;
      }
    }
  }
`;

export const TopicIconWrapper = styled.div<{
  isClickable: boolean;
}>`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  transition: all 0.2s ease-in-out;
  height: 100%;
  display: flex;

  &:hover {
    border-color: var(--brand-color-primary);
  }

  > div {
    flex: 1;
    width: 100%;
    padding: var(--spacing-3x);
  }
`;
