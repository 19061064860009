import styled from 'styled-components';
import { media } from '@mf/common/utils/styles/index';

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${media.lessThan('mobile')} {
    margin-top: calc(-1 * var(--size-5x));
  }
`;

export const Grid = styled.div<{
  isBanner?: boolean;
  hasSmallGap?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1440px;
  gap: ${(props) =>
    props.hasSmallGap ? 'var(--spacing-6x)' : 'var(--spacing-20x)'};
  padding: ${(props) =>
    props.isBanner
      ? '0 var(--spacing-5x)'
      : 'var(--spacing-6x) var(--spacing-5x)'};

  ${media.lessThan('touch')} {
    gap: var(--spacing-15x);
    padding: var(--spacing-7x) var(--spacing-5x);
  }

  ${media.lessThan('mobile')} {
    gap: var(--spacing-10x);
    padding: ${(props) =>
      props.isBanner
        ? '0 var(--spacing-2x)'
        : 'var(--spacing-7x) var(--spacing-2x)'};
  }
`;

export const Container = styled.div`
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2x);
  padding: 0 var(--spacing-5x);

  ${media.lessThan('mobile')} {
    max-width: 735px;
    gap: var(--spacing-4x);
    padding: 0 var(--spacing-2x);
  }
`;
