'use client';
import { useRouter } from 'next/navigation';
import { useCallback } from 'react';
import {
  TTopBar,
  TTopBarItem,
} from '../../../repositories/aecomStrapiCms/topBar/types';
import { DrawerCouponProps } from '../../DrawerCoupon/DrawerCoupon.types';

interface OnClickProps {
  item: TTopBarItem;
  setDrawerCoupon: React.Dispatch<React.SetStateAction<DrawerCouponProps>>;
}

interface SetDrawerCouponDataProps {
  coupon: TTopBar;
  setDrawerCoupon: React.Dispatch<React.SetStateAction<DrawerCouponProps>>;
}

export const useTopBarHooks = () => {
  const { push } = useRouter();

  const setDrawerCouponData = useCallback(
    ({ coupon, setDrawerCoupon }: SetDrawerCouponDataProps) => {
      setDrawerCoupon({
        code: coupon?.code || '',
        title: coupon?.title || '',
        description: coupon?.description || '',
        isOpen: true,
        faq: coupon.faq,
        onClose: () =>
          setDrawerCoupon((state) => ({
            ...state,
            isOpen: false,
          })),
      });
    },
    [],
  );

  const redirect = useCallback(
    (href: string, target: string = '_self') => {
      if (target === '_blank') {
        window.open(href, '_blank');
      } else {
        push(href);
      }
    },
    [push],
  );

  const onClick = useCallback(
    ({ item, setDrawerCoupon }: OnClickProps) => {
      const topbar = item?.attributes?.topbar[0];

      if (topbar) {
        if (topbar?.__component === 'top-bar.drawer-type') {
          setDrawerCouponData({
            coupon: topbar,
            setDrawerCoupon,
          });
        } else if (topbar?.__component === 'top-bar.link-type') {
          redirect(topbar?.href || '', topbar?.target || '_self');
        }
      }
    },
    [setDrawerCouponData, redirect],
  );

  return {
    onClick,
    setDrawerCouponData,
    redirect,
  };
};
