import React, { useEffect } from 'react';
import {
  FsTopicIcon,
  HeadingTag,
  TopicIconOrientation,
} from '@printi/ds-offset-react-core';
import { useLogManager } from '@mf/common/components/Logs/LogManager.hooks';
import { useSession } from 'next-auth/react';
import { CustomSession } from '@mf/common/config/nextAuth/types';
import { useCartStore } from '@mf/common/store/cart';
import * as S from './TopicIconsGroup.styles';

export interface ITopicIcon {
  icon: string;
  heading: string;
  paragraph: React.ReactNode;
  onClick?: () => void;
  topic_icon_id: number;
  url?: string;
  target?: '_blank' | '_self';
}

interface TopicIconsGroupProps {
  topicIcons: ITopicIcon[];
}

export const TopicIconsGroup = ({ topicIcons }: TopicIconsGroupProps) => {
  const { trackEvent } = useLogManager();
  const { data: session } = useSession();
  const { customerId } = useCartStore();
  const userSession = session as CustomSession;

  useEffect(() => {
    trackEvent('view_topic_icons', {
      session_id: userSession,
      customer_id: customerId,
      topic: [
        'Primeira compra?',
        'Parcele em até 12x',
        'Garantia de satisfação',
        'Precisa de ajuda?',
      ],
    });
  }, [trackEvent, userSession, topicIcons, customerId]);

  const handleClick = (topic: ITopicIcon) => {
    topic.onClick?.();

    trackEvent('select_content', {
      content_type: 'topic_icon',
      content_id: topic.topic_icon_id,
      topic_name: topic.heading,
      session_id: userSession,
      customer_id: customerId,
    });

    if (topic.url) {
      window.open(topic.url, topic.target || '_self');
    }
  };

  return (
    <S.Container>
      <S.TopicIconsContainer>
        <S.TopicIconsWrapper>
          {topicIcons.map((topic, index) => (
            <S.TopicIconWrapper
              key={index}
              onClick={() => handleClick(topic)}
              data-testid={`topic-icon-${index}`}
              isClickable={!!topic.onClick || !!topic.url}
            >
              <FsTopicIcon
                icon={topic.icon}
                orientation={TopicIconOrientation.Horizontal}
                headingProps={{
                  children: topic.heading,
                  tag: HeadingTag.H3,
                }}
                paragraph={topic.paragraph}
              />
            </S.TopicIconWrapper>
          ))}
        </S.TopicIconsWrapper>
      </S.TopicIconsContainer>
    </S.Container>
  );
};
