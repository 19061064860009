import { RefObject, useEffect, useState } from 'react';

/**
 * Custom Hook to dynamically adjust the margins of an element based on its scroll position.
 * @param {RefObject<HTMLDivElement>} ref The reference to the HTML element
 * @param {boolean} isMobile Flag to determine if the device is mobile
 * @example const ref = useRef<HTMLDivElement>(null);
 *   const [marginRight, marginLeft] = useDynamicMargins(ref, isMobile);
 * <div ref={ref} style={{ marginRight, marginLeft }}>Content</div>
 * @returns {[string, string]} An array containing the right and left margins
 */

type UseDynamicMargins = (
  ref: RefObject<HTMLDivElement>,
  isMobile: boolean,
) => [string, string];

const useDynamicMargins: UseDynamicMargins = (ref, isMobile) => {
  const margin = isMobile ? '-16px' : '0px';
  const [marginRight, setMarginRight] = useState(margin);
  const [marginLeft, setMarginLeft] = useState('0px');

  useEffect(() => {
    const currentRef = ref.current;

    const handleScroll = () => {
      if (currentRef) {
        const { scrollLeft, scrollWidth, clientWidth } = currentRef;
        const atStart = scrollLeft === 0;
        const atEnd = scrollLeft + clientWidth >= scrollWidth;

        if (atStart) {
          setMarginRight(margin);
          setMarginLeft('0px');
        } else if (atEnd) {
          setMarginRight('0px');
          setMarginLeft(margin);
        } else {
          setMarginRight(margin);
          setMarginLeft(margin);
        }
      }
    };

    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
      handleScroll();
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [ref, margin]);

  return [marginRight, marginLeft];
};

export default useDynamicMargins;
