'use client';

import { useState } from 'react';
import { useSession } from 'next-auth/react';
import { useLogManager } from '@mf/common/components/Logs/LogManager.hooks';
import { useCartStore } from '@mf/common/store/cart';
import { CustomSession } from '@mf/common/config/nextAuth';
import strapiBannersHome from '@mf/promotions/repositories/aecomStrapiCms/bannerHome';
import { HomeLettering } from '@/components/main/HomeLettering';
import { QualityPrinti } from '@/components/main/QualityPrinti';
import { HomeTestimonies } from '@/components/main/HomeTestimonies';
import { HomeBanners } from '@/components/main/HomeBanners';
import { BusinessProducts } from '@/components/main/BusinessProducts';
import { ITopicIcon, TopicIconsGroup } from '@/components/main/TopicIconsGroup';
import { FirstPurchaseModal } from '@/components/main/TopicIconsGroup/Modals/FirstPurchaseModal';
import { TShowcases } from '@/repositories/aecomStrapiCms/showcase/types';
import { Showcases } from '../HomeV2/components/Showcases';
import { HomeV2PopularCategories } from '../HomeV2/components/PopularCategories';
import { HomeV2BannerHybrid } from '../HomeV2/components/BannerHybrid';
import * as S from './Home.styles';

interface HomeScreenProps {
  homeShowcases: TShowcases[];
}

export function HomeScreen({ homeShowcases }: HomeScreenProps) {
  const [isFirstPurchaseModalOpen, setIsFirstPurchaseModalOpen] =
    useState(false);

  const auth = {
    baseUrl: process.env.BFF_API_BASE_URL,
  };

  const { data: session } = useSession();
  const { trackEvent } = useLogManager();
  const { customerId } = useCartStore();
  const userSession = session as CustomSession;

  const { useBannersHome } = strapiBannersHome(auth);
  const { data: configBanner } = useBannersHome();
  const getHeroBannerAttributes = () => configBanner?.data?.hero?.attributes;
  const getHeroBannerId = () => configBanner?.data?.hero?.id ?? null;

  const getCommonBannerAttributes = () =>
    configBanner?.data?.common?.attributes;
  const getCommonBannerId = () => configBanner?.data?.common?.id ?? null;

  const handleFirstPurchaseModal = (state: boolean) => {
    setIsFirstPurchaseModalOpen(state);
    const event = state ? 'view_modal' : 'close_modal';

    trackEvent(event, {
      modal_name: 'Primeira Compra',
      modal_id: 'modal_primeira_compra',
      session_id: userSession,
      customer_id: customerId,
    });
  };

  const topicIcons = [
    {
      icon: 'CheckoutLine',
      heading: 'Primeira compra?',
      paragraph: 'Cupom 25% OFF! Confira aqui',
      onClick: () => handleFirstPurchaseModal(true),
      topic_icon_id: 1,
    },
    {
      icon: 'CreditCardLine',
      heading: 'Parcele em até 12x',
      paragraph: 'Nas compras acima de R$1000',
      topic_icon_id: 2,
    },
    {
      icon: 'StickerLine',
      heading: '+ 3MM de pedidos',
      paragraph: 'Em todos os estados brasileiros',
      topic_icon_id: 3,
    },
    {
      icon: 'WhatsappLine',
      heading: 'Precisa de ajuda?',
      paragraph: 'Fale com a gente no whatsapp',
      url: 'https://api.whatsapp.com/send?phone=551141183816',
      target: '_blank',
      topic_icon_id: 4,
    },
  ] satisfies ITopicIcon[];

  return (
    <>
      <S.Main>
        <S.Container>
          <HomeV2BannerHybrid
            banner={getHeroBannerAttributes()}
            bannerId={getHeroBannerId()}
            isHeroBanner
          />

          <TopicIconsGroup topicIcons={topicIcons} />

          <HomeV2PopularCategories />
        </S.Container>

        <S.Grid className={homeShowcases?.[0]?.attributes?.slug || ''}>
          <Showcases showcases={homeShowcases} isHero />
        </S.Grid>

        <S.Grid isBanner>
          <HomeV2BannerHybrid
            banner={getCommonBannerAttributes()}
            bannerId={getCommonBannerId()}
          />
        </S.Grid>

        <S.Grid hasSmallGap>
          <Showcases showcases={homeShowcases} />
        </S.Grid>

        <S.Grid>
          <HomeBanners />
        </S.Grid>

        <HomeLettering />

        <S.Grid>
          <BusinessProducts />
          <HomeTestimonies />
          <QualityPrinti />
        </S.Grid>
      </S.Main>

      <FirstPurchaseModal
        isOpen={isFirstPurchaseModalOpen}
        onClose={() => handleFirstPurchaseModal(false)}
      />
    </>
  );
}
