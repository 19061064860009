import { FsHeading } from '@printi/ds-offset-react-core';
import styled, { css } from 'styled-components';

export const BannerTitle = styled(FsHeading)`
  color: var(--text-description-color);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-default);
  font-size: var(--font-size-2xs);
  line-height: var(--line-height-md);
  margin: 0;

  ${({ inverse }) =>
    inverse &&
    css`
      color: var(--inverse-description-color);
    `}
`;
