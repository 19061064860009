/* eslint-disable import/order */
'use client';

import {
  BannerItemBgColor,
  BannerItemContentType,
  BannerMosaic,
  ButtonSize,
  DescriptionTag,
  FsBannerItem,
  FsButton,
  FsDescription,
  FsHeading,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import { useLayout } from '@mf/common/components/Media/Layout';
import { useTopBarHooks } from '@mf/promotions/components/TopBar/Client';
import { TTopBarItem } from '@mf/promotions/repositories/aecomStrapiCms/topBar/types';
import { usePromotions } from '@mf/common/contexts/promotions/promotions';
import { useRouter } from 'next/navigation';
import React from 'react';
import Image from 'next/image';
import * as S from './HomeBanners.styles';
import indiqueAPrinti from '@/assets/Home/indique-a-printi.webp';

export function HomeBanners() {
  const router = useRouter();
  const { isMobile } = useLayout();
  const { onClick } = useTopBarHooks();
  const { setDrawerCoupon } = usePromotions();

  const videoRef = React.useRef<HTMLVideoElement>(null);

  const createContentSlotContent = ({
    inverse,
    heading,
    description,
  }: {
    inverse: boolean;
    heading: string;
    description?: string;
  }) => (
    <>
      <FsDescription inverse={inverse} tag={DescriptionTag.H2}>
        {description}
      </FsDescription>
      <FsHeading size={HeadingSize.SM} tag={HeadingTag.SPAN} inverse={inverse}>
        {heading}
      </FsHeading>
    </>
  );

  const onClickCoupon = () => {
    onClick({ item: promoNewPrinti, setDrawerCoupon });
    window.dataLayer.push({
      event: 'genericEvent',
      event_name: 'click_banner',
      event_parameter_1:
        '25% de desconto em todo o site usando o cupom #VEMPRAPRINTI',
      event_parameter_2: 'home',
    });
  };

  const promoNewPrinti: TTopBarItem = {
    id: 2,
    attributes: {
      createdAt: '',
      updatedAt: '',
      desktopCta: undefined,
      mobileCta: undefined,
      desktopText: '',
      mobileText: '',
      publishedAt: '',
      publishAt: null,
      unpublishAt: null,
      topbar: [
        {
          target: '_self',
          id: 1,
          __component: 'top-bar.drawer-type',
          title: 'Quem indica ganha. E quem é indicado também!',
          code: 'VEMPRANOVAPRINTI',
          href: '',
          description:
            'Ao participar, você recebe um código exclusivo para compartilhar com seus amigos. Quando seu amigo utilizar o código na sua primeira compra, ele ganha 30% de desconto (até o limite de R$ 75). Assim que o pedido for entregue, é a sua vez: você recebe R$ 20 em Printi Money para usar em suas futuras compras.',
          faq: [
            {
              id: 1,
              question: 'O que é o programa de Indicação da Printi?',
              answer:
                'O programa permite que você indique amigos para a Printi e ganhe benefícios. Você recebe créditos em Printi Money e seus amigos ganham descontos na primeira compra.',
            },
            {
              id: 2,
              question: 'Há limites para o número de indicações?',
              answer:
                'Não! Você pode compartilhar o código com várias pessoas e acumular créditos para cada primeira compra realizada pelos indicados.',
            },
            {
              id: 3,
              question: 'O que é Printi Money?',
              answer:
                'Printi Money é o crédito que você recebe ao participar do programa. Ele pode ser usado em compras futuras na Printi e é liberado assim que o pedido do seu amigo for entregue. Se o pedido for cancelado, os créditos não serão concedidos.',
            },
            {
              id: 4,
              question: 'Qual a validade do Printi Money?',
              answer:
                'Os créditos têm validade de 6 meses a partir da data de recebimento.',
            },
            {
              id: 5,
              question:
                'O que acontece se o código de indicação não for usado ou estiver errado?',
              answer:
                'Para que o benefício seja concedido, o código deve ser inserido corretamente no carrinho. Caso contrário, nem o desconto nem o bônus em Printi Money serão aplicados.',
            },
            {
              id: 6,
              question: 'Onde posso encontrar meu código de indicação?',
              answer:
                'O código está disponível na sua conta Printi, na seção Indicações.',
            },
          ],
        },
      ],
    },
  };

  const bannerOficial = (
    <FsBannerItem
      className="banner-item-1"
      bannerImage={
        <Image
          // src="https://d1br4h274rc9sc.cloudfront.net/content/Indique_a_Printi_Banner_21_28b6c3963b.png"
          src={indiqueAPrinti} // TODO: colocar imagem oficial em webp via cloudfront
          alt="banner-oficial"
          width={639}
          height={352}
        />
      }
      contentType={BannerItemContentType.Text}
      backgroundColor={BannerItemBgColor.Grey}
      showCounter={false}
      contentSlot={createContentSlotContent({
        inverse: false,
        heading: (
          <p style={{ maxWidth: '310px', margin: 0 }}>
            Desconto para o seu amigo, recompensa para você.
          </p>
        ) as unknown as string,
        description: 'Indique a Printi e acumule benefícios exclusivos',
      })}
      actionSlot={
        <>
          {isMobile ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 'var(--spacing-2x)',
              }}
            >
              <FsButton
                size={ButtonSize.LG}
                style={{
                  width: 'fit-content',
                }}
              >
                Como funciona
              </FsButton>
            </div>
          ) : (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <FsButton size={ButtonSize.LG}>Como funciona</FsButton>
            </div>
          )}
        </>
      }
      inverse={false}
      onClick={onClickCoupon}
    />
  );

  const item2Content = (
    <FsBannerItem
      className="banner-item-2"
      contentType={BannerItemContentType.Text}
      backgroundColor={BannerItemBgColor.LightGrey}
      showCounter={false}
      bannerImage={
        <Image
          src="https://d1br4h274rc9sc.cloudfront.net/content/Object_7e4d9b5f31_e0e0f2245a.svg"
          alt="Banner Image"
          width={137}
          height={179}
        />
      }
      contentSlot={createContentSlotContent({
        inverse: false,
        heading: (
          <p style={{ maxWidth: '310px', margin: 0 }}>
            O que mudou, canais de venda, suporte e muito mais.
          </p>
        ) as unknown as string,
        description: 'Central de Ajuda',
      })}
      actionSlot={<FsButton size={ButtonSize.LG}>Ajuda</FsButton>}
      inverse={false}
      onClick={() => {
        router.push('/central-de-ajuda/');
        window.dataLayer.push({
          event: 'genericEvent',
          event_name: 'click_banner',
          event_parameter_1: 'Central de Ajuda',
          event_parameter_2: 'home',
        });
      }}
    />
  );

  const item3Content = (
    <FsBannerItem
      className="banner-item-3"
      style={{ backgroundColor: '#000' }}
      contentType={BannerItemContentType.Brand}
      slotImage={
        <S.Video
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
          src="https://d1br4h274rc9sc.cloudfront.net/content/videoia_2ef7426ebc.webm"
        ></S.Video>
      }
      showCounter={false}
      contentSlot={createContentSlotContent({
        inverse: true,
        heading: (
          <S.BigHeading>
            Printi AI: descubra o que já estamos fazendo e o que ainda vem por
            aí.
          </S.BigHeading>
        ) as unknown as string,
        description: 'Inteligência Artificial',
      })}
      actionSlot={
        <FsButton size={ButtonSize.LG} inverse={true}>
          Saiba mais
        </FsButton>
      }
      inverse={true}
      onMouseEnter={() => (videoRef.current!.playbackRate = 0.5)}
      onMouseLeave={() => (videoRef.current!.playbackRate = 1)}
      onClick={() => {
        router.push('/inteligencia-artificial/');
        window.dataLayer.push({
          event: 'genericEvent',
          event_name: 'start_ai_assistant',
          event_parameter_1: 'ai_home_banner',
        });
      }}
    />
  );

  return (
    <S.Banner>
      <S.Banners
        bannerMosaic={BannerMosaic.SixtyFortyHundred}
        item1={bannerOficial}
        item2={item2Content}
        item3={item3Content}
      />
    </S.Banner>
  );
}
