import { media } from '@mf/common/utils/styles/index';
import styled from 'styled-components';

export const PreviewVideo = styled.div.withConfig({
  shouldForwardProp: (prop) => !['showOverlay', 'isVisible'].includes(prop),
})<{ showOverlay: boolean; isVisible: boolean }>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2000;
  inset: 0;
  box-sizing: border-box;
  transition: opacity 400ms ease-in-out;
  align-items: center;
  justify-content: center;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};

  ${({ showOverlay }) =>
    showOverlay && 'background-color: var(--_default-overlay);'}

  .videoLoading {
    width: 90vw;
    height: 40vh;
    max-width: 800px;
    max-height: max-content;
    overflow: hidden;
    position: relative;
    background-color: var(--elements-bg-color-02);
    box-shadow: var(--shadow-level-3);

    ${media.greaterThan('mobile')} {
      min-height: 456px;
    }
  }
`;

export const Container = styled.div`
  width: 100%;

  ${media.lessThan('mobile')} {
    margin-top: var(--spacing-10x);
  }
`;

export const Testimonies = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-7x) var(--spacing-2x);

  ${media.lessThan('mobile')} {
    flex-wrap: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none;
    justify-content: flex-start;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Column = styled.div`
  flex: 1 1 calc(50% - var(--spacing-2x));

  ${media.lessThan('mobile')} {
    flex: 0 0 280px;
    max-width: 280px;
  }
`;

export const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  padding: var(--spacing-2x);
  margin-top: var(--spacing-5x);

  ${media.lessThan('mobile')} {
    display: none;
  }
`;

export const CardTestimonyWrapper = styled.div`
  > div {
    cursor: pointer;
  }
`;
