import { useEffect, useState } from 'react';

const BREAKPOINTS = {
  DESKTOP_SMALL: 1024,
  DESKTOP_MEDIUM: 1366,
  DESKTOP_LARGE: 1440,
};

const SHOWCASE_ITEMS_RULE = {
  DESKTOP_SMALL: 4,
  DESKTOP_MEDIUM: 5,
  DESKTOP_LARGE: 6,
};

type TConditionRule = {
  condition: boolean;
  show: boolean;
  items: number;
};

export const useCarouselNavigation = (productsLength: number = 0) => {
  const [shouldShowNavigation, setShouldShowNavigation] =
    useState<boolean>(false);
  const [itemsPerView, setItemsPerView] = useState(
    SHOWCASE_ITEMS_RULE.DESKTOP_LARGE,
  );

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      const breakpointRules = [
        {
          condition: width < BREAKPOINTS.DESKTOP_SMALL,
          show: false,
          items: SHOWCASE_ITEMS_RULE.DESKTOP_SMALL,
        },
        {
          condition:
            width >= BREAKPOINTS.DESKTOP_SMALL &&
            width < BREAKPOINTS.DESKTOP_MEDIUM,
          show: productsLength > SHOWCASE_ITEMS_RULE.DESKTOP_SMALL,
          items: SHOWCASE_ITEMS_RULE.DESKTOP_SMALL,
        },
        {
          condition:
            width >= BREAKPOINTS.DESKTOP_MEDIUM &&
            width < BREAKPOINTS.DESKTOP_LARGE,
          show: productsLength > SHOWCASE_ITEMS_RULE.DESKTOP_MEDIUM,
          items: SHOWCASE_ITEMS_RULE.DESKTOP_MEDIUM,
        },
        {
          condition: width >= BREAKPOINTS.DESKTOP_LARGE,
          show: productsLength > SHOWCASE_ITEMS_RULE.DESKTOP_LARGE,
          items: SHOWCASE_ITEMS_RULE.DESKTOP_LARGE,
        },
      ] satisfies TConditionRule[];

      const rule =
        breakpointRules.find((rule) => rule.condition) ??
        ({} as TConditionRule);

      setShouldShowNavigation(rule.show ?? false);
      setItemsPerView(rule.items ?? SHOWCASE_ITEMS_RULE.DESKTOP_LARGE);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [productsLength]);

  return { shouldShowNavigation, itemsPerView };
};
