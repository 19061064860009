import styled from 'styled-components';
import { media } from '../../utils/styles';

export const Container = styled.div`
  display: flex;
  width: 220px;
  flex-direction: column;
  text-align: center;
  gap: var(--spacing-1_5x);
  border-radius: var(--border-radius-none);
  align-items: center;

  span {
    font: var(--_label-md-1-font);
    letter-spacing: var(--_label-md-1-letter-spacing);
    max-width: 180px;
    color: var(--text-label-color);
  }

  ${media.lessThan('mobile')} {
    width: var(--size-15x);
  }

  @media (min-width: 1021px) and (max-width: 1400px) {
    flex-grow: 1;
    flex-basis: 0;
    min-width: var(--size-15x);

    max-width: 180px;

    width: 147px;
    height: 147px;
  }
`;

export const Content = styled.div`
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  background-color: var(--elements-bg-color-02);

  border: none;
  border-radius: var(--border-radius-circular);
  height: var(--size-25x);
  width: var(--size-25x);
  transition-timing-function: var(--curve-productive-exit);
  transition-duration: var(--duration-fast-02);
  box-sizing: border-box;

  ${media.lessThan('mobile')} {
    height: var(--size-15x);
    width: var(--size-15x);
  }

  ${media.greaterThan('mobile')} {
    &:hover {
      transition-timing-function: var(--curve-productive-entrance);
      cursor: pointer;
      scale: 1.05;
    }
  }

  @media (min-width: 1021px) and (max-width: 1400px) {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    svg {
      width: var(--size-3x) !important;
      height: var(--size-3x) !important;
    }
  }
`;
