'use client';

import {
  BannerHybridBgColor,
  BannerHybridContentType,
  BannerHybridSize,
  ButtonSize,
  FsBannerHybrid,
  FsButton,
  FsHeading,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import { ComponentProps } from 'react';
import { CustomSession } from '@mf/common/config/nextAuth';
import { useBannerHybridHooks } from './BannerHybrid.hooks';
import { BannerTitle } from './BannerHybrid.styles';

type BannerProps = {
  description?: string | null;
  heading?: string;
  actionUrl: string;
  actionLabel: string;
  actionLabelIcon?: string;
  bannerId: number | null;
  bannerImage: string | null;
  bannerImageAlt: string;
  inverseHeading?: boolean;
  inverseButton?: boolean;
  showCountdown?: boolean;
  backgroundColor?: BannerHybridBgColor;
  imageOnlyMode: boolean | undefined;
  imageLg?: string;
  imageMd?: string;
  imageSm?: string;
  imageAlt?: string;
  imageTitle?: string;
  id?: string | null;
  session?: CustomSession | null;
} & ComponentProps<typeof FsBannerHybrid>;

export const BannerHybrid = ({
  description,
  heading,
  actionUrl,
  actionLabel,
  actionLabelIcon,
  actionTarget,
  // bannerId,
  bannerImage,
  inverseHeading,
  inverseButton,
  showCountdown,
  size,
  backgroundColor,
  imageOnlyMode,
  imageLg,
  imageMd,
  imageSm,
  imageAlt,
  imageTitle,
  id = '',
  // session = null,
}: BannerProps) => {
  // const { trackEvent } = useLogManager();
  const { actionSlot, onZeroTime } = useBannerHybridHooks();

  // const startTime = useRef(performance.now());
  // const [loadTime, setLoadTime] = useState<number | null>(null);

  // useEffect(() => {
  //   setLoadTime(performance.now() - startTime.current);
  // }, []);

  // useEffect(() => {
  //   if (loadTime !== null) {
  //     trackEvent('view_promotion_load_time', {
  //       details: {
  //         banner_id: bannerId,
  //         load_time_ms: loadTime,
  //         page_url: window.location.href,
  //       },
  //     });
  //   }
  // }, [loadTime, bannerId, trackEvent]);

  // const user = session?.user ?? { first_name: '', email: '' };

  // const formattedUser = useMemo(
  //   () => ({
  //     name: user.first_name,
  //     email: user.email,
  //   }),
  //   [user.first_name, user.email],
  // );

  // const handleImageClick = () => {
  //   trackEvent('click', { details: { user: formattedUser } });
  // };

  return (
    <div style={{ padding: '0' }}>
      <FsBannerHybrid
        imageOnlyMode={imageOnlyMode}
        imageLg={imageLg}
        imageMd={imageMd}
        imageSm={imageSm}
        imageAlt={imageAlt}
        imageTitle={imageTitle}
        actionTarget={actionTarget}
        actiontUrl={actionUrl}
        actionSlot={
          <FsButton
            size={ButtonSize.LG}
            onClick={() => actionSlot({ actionUrl })}
            icon={actionLabelIcon}
            inverse={inverseButton ?? false}
          >
            {actionLabel}
          </FsButton>
        }
        bannerImage={bannerImage || ''}
        contentSlot={
          <>
            <BannerTitle tag={HeadingTag.H2} inverse={inverseHeading ?? false}>
              {description || ''}
            </BannerTitle>
            <FsHeading
              size={HeadingSize.SM}
              inverse={inverseHeading ?? false}
              tag={HeadingTag.H2}
              style={{ maxWidth: 380 }}
            >
              {heading}
            </FsHeading>
          </>
        }
        contentType={BannerHybridContentType.Text}
        onZeroTime={onZeroTime}
        size={size || BannerHybridSize.SM}
        timerHours={1}
        timerLabel="Acaba em"
        timerMinutes={0}
        timerSeconds={0}
        showCountdown={showCountdown}
        backgroundColor={backgroundColor}
        id={id}
      />
    </div>
  );
};
