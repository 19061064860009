'use client';

import React, { useCallback } from 'react';
import { FsButton, FsModal, ModalType } from '@printi/ds-offset-react-core';
import styled from 'styled-components';

interface FirstPurchaseModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalContainer = styled(FsModal)`
  z-index: 1002; // Should be higher than header

  @media only screen and (max-width: 1023px) {
    #modal {
      width: 90vw;
      bottom: unset;
    }
  }
`;

const ModalContent = styled.div`
  max-height: 50vh;
  overflow-y: auto;

  @media only screen and (max-width: 640px) {
    max-height: 20vh;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--neutral-color-lighter);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--neutral-color-light);
    border-radius: 3px;
  }

  > h3 {
    margin-top: 0;
    margin-bottom: var(--spacing-3x);
    font-family: var(--font-family-base, Inter);
  }
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3x);
`;

const Step = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const StepMarker = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #000000;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  flex-shrink: 0;
  font-family: var(--font-family-base, Inter);
`;

const StepContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-1x);
  text-align: left;
`;

const StepDescription = styled.p`
  color: var(--neutral-color-dark);
  margin: 0;
  font-size: var(--font-size-xs);
  font-family: var(--font-family-base, Inter);
`;

export const FirstPurchaseModal = ({
  isOpen,
  onClose,
}: FirstPurchaseModalProps) => {
  const steps = [
    {
      marker: '1',
      title: 'Escolha o produto:',
      description:
        'após seleção do item desejado, ajuste as opções de personalização e baixe o gabarito para enviar a arte corretamente.',
    },
    {
      marker: '2',
      title: 'Configure a entrega:',
      description:
        'receba no seu endereço ou retire em um ponto de coleta. Se preferir, é possível buscar direto na fábrica da Printi.',
    },
    {
      marker: '3',
      title: 'Defina a forma de pagamento:',
      description:
        'aceitamos Cartão de Crédito, Débito, Pix (aprovação instantânea) e Boleto. Escolha a melhor opção!',
    },
    {
      marker: '4',
      title: 'Ganhe 25% OFF em compras acima de R$150:',
      description:
        'insira o código VEMPRAPRINTI no campo "Cupom de Desconto" e aproveite. Desconto de até R$150 para novos clientes.',
    },
    {
      marker: '5',
      title: 'Envie sua arte:',
      description:
        'seu arquivo precisa seguir os padrões dos nossos gabaritos e ser enviado em PDF (máx. 200MB).',
    },
    {
      marker: '✓',
      title: 'Pronto!',
      description: 'Agora é só acompanhar o pedido.',
    },
  ];

  const onFirstPurchaseClick = useCallback(() => {
    const url = 'http://printi.com.br/produtos';
    window.open(url, '_self');

    // TODO: add eventTracker
    onClose();
  }, [onClose]);

  return (
    <ModalContainer
      type={ModalType.Slot}
      isOpen={isOpen}
      onClose={onClose}
      heading="Personalizar nunca foi tão fácil!"
      fixedBarActionSlot={
        <FsButton onClick={onFirstPurchaseClick}>Usar cupom agora!</FsButton>
      }
    >
      <ModalContent>
        <h3>Veja como aproveitar seu desconto em 5 passos simples:</h3>
        <StepContainer>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepMarker>{step.marker}</StepMarker>
              <StepContent>
                <StepDescription>{`${step.title} ${step.description}`}</StepDescription>
              </StepContent>
            </Step>
          ))}
        </StepContainer>
      </ModalContent>
    </ModalContainer>
  );
};
