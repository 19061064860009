import { Carousel } from '@mf/common/components/index';
import { useCarouselNavigation } from '@mf/navigation/hooks/useCarouselNavigation';
import { TShowcases } from '@/repositories/aecomStrapiCms/showcase/types';

const ShowcaseItem = ({ showcase }: { showcase: TShowcases | undefined }) => {
  const { shouldShowNavigation, itemsPerView } = useCarouselNavigation(
    showcase?.attributes?.products?.data?.length,
  );

  return (
    <Carousel
      key={showcase?.id}
      heading={showcase?.attributes?.title || 'Carregando...'}
      cards={showcase?.attributes?.products?.data}
      loading="eager"
      disableNavigation={!shouldShowNavigation}
      itemsPerView={itemsPerView}
    />
  );
};

interface ShowcasesProps {
  showcases: TShowcases[] | undefined;
  isHero?: boolean;
}

export const Showcases = ({ showcases, isHero = false }: ShowcasesProps) => {
  if (!showcases) {
    return <ShowcaseItem showcase={undefined} />;
  }

  if (isHero) {
    return <ShowcaseItem showcase={showcases[0]} />;
  }

  return (
    <>
      {showcases.slice(1).map((showcase, index) => (
        <ShowcaseItem
          key={`showcase-${showcase.id}-${index}`}
          showcase={showcase}
        />
      ))}
    </>
  );
};
