import styled from 'styled-components';
import { media } from '@mf/common/utils/styles/index';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2x);

  ${media.lessThan('mobile')} {
    max-width: 735px;
    gap: var(--spacing-4x);
  }
`;

export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-2x);
  align-self: stretch;
  text-align: center;
`;

export const BrandContainer = styled.div`
  width: 200px;
  height: 90px;
`;

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3x);
`;

export const ShortcutsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-2x);
  justify-content: space-between;
  width: 100%;

  ${media.lessThan('mobile')} {
    display: none;
  }
`;

export const ParagraphWrapper = styled.div`
  display: flex;
`;

export const Shortcuts = styled.div`
  display: flex;
  gap: var(--spacing-2x);

  ${media.lessThan('touch')} {
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  ${media.lessThan('mobile')} {
    width: calc(100% + 16px);
  }
`;

export const Categories = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;

  ${media.lessThan('touch')} {
    overflow-x: scroll;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  ${media.lessThan('mobile')} {
    gap: var(--spacing-2x);

    > a:last-of-type {
      order: -1;
    }
  }
`;

export const ShortcutImageWrapper = styled.div`
  position: relative;

  > a {
    max-width: 212px;

    ${media.lessThan('mobile')} {
      max-width: 120px;
    }

    > div {
      position: relative;

      ${media.lessThan('mobile')} {
        width: var(--size-15x);

        &:first-child {
          width: var(--size-15x);
          height: var(--size-15x);
        }
      }
    }
  }

  @media (min-width: 1021px) and (max-width: 1400px) {
    flex-grow: 1;
    flex-basis: 0;
    min-width: var(--size-15x);

    > a {
      max-width: 147px;

      div {
        width: 147px;
        height: 147px;
      }
    }
  }
`;

export const CarouselBannersContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const CarouselBannerWrapper = styled.div`
  ${media.lessThan('mobile')} {
    width: 300px;
    height: 480px;
    > div {
      height: 100%;
    }
    img {
      object-fit: none;
    }
  }
  img {
    object-fit: contain;
  }
`;
