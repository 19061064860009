import styled from 'styled-components';
import { media } from '@mf/common/utils/styles/index';
import { Container as CardProductSkeletonContainer } from '@mf/common/components/CardProductSkeleton/CardProductSkeleton.styles';

export const BusinessProducts = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-5x);
  width: 100%;
`;

export const ChipContainer = styled.div`
  display: flex;
  gap: var(--spacing-1x);

  justify-content: flex-start;

  overflow-x: auto;
  -ms-overflow-style: none;
  width: calc(100% + 16px);

  &::-webkit-scrollbar {
    display: none;
  }

  ${media.greaterThan('desktop')} {
    flex-wrap: wrap;
  }
`;

export const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: dense;
  gap: var(--spacing-6x) var(--spacing-3x);
  width: 100%;

  ${media.lessThan('mobile')} {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    gap: var(--spacing-2x);
    width: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const CardProductWrapper = styled.div`
  display: flex;
  min-width: 240px;
  width: 240px;
  cursor: pointer;

  a {
    display: block;
    width: 100%;

    div {
      position: relative;
    }
  }

  ${media.greaterThan('touch')} {
    max-width: 322px;
    width: 100%;
  }

  ${CardProductSkeletonContainer} {
    width: 100%;
  }
`;

export const BannerColumn = styled.div`
  grid-column: span 2;
`;

export const BannerContainer = styled.div`
  height: 410px;
`;

export const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
