'use client';

import React, { useRef } from 'react';
import {
  FsShortcutImage,
  FsTopSection,
  HeadingTag,
  LinkTarget,
  ShortcutImageSize,
  TopSectionType,
} from '@printi/ds-offset-react-core';
import { useRouter } from 'next/navigation';
import { useLayout } from '@mf/common/components/Media/Layout';
import useDynamicMargins from '@mf/common/utils/hooks/useDynamicMargins/index';
import Link from 'next/link';
import { ShortcutIcon } from '@mf/common/components/index';
import Image from 'next/image';
import strapiProductCategory from 'repositories/aecomStrapiCms/productCategory';
import * as S from './styles';

export function HomeV2PopularCategories() {
  const categoriesRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const { isMobile } = useLayout();

  const auth = {
    baseUrl: process.env.BFF_API_BASE_URL,
  };

  const [categoriesMarginRight, categoriesMarginLeft] = useDynamicMargins(
    categoriesRef,
    isMobile,
  );

  const { useProductCategories } = strapiProductCategory(auth);
  const { data: categories } = useProductCategories();

  return (
    <div>
      <FsTopSection
        heading="Categorias populares"
        showDescription
        type={TopSectionType.Default}
        description=""
        headingProps={{ tag: HeadingTag.H2 }}
      />
      <S.Categories
        ref={categoriesRef}
        style={
          isMobile
            ? {
                marginRight: `${categoriesMarginRight}`,
                marginLeft: `${categoriesMarginLeft}`,
              }
            : {}
        }
      >
        {categories?.map((category) => {
          if (category.attributes.showShortcut) {
            return (
              <S.ShortcutImageWrapper key={category.id}>
                <FsShortcutImage
                  image={
                    <Image
                      src={
                        category.attributes.shortcutImage?.data?.attributes.url
                      }
                      alt={category.attributes?.shortcutImageAlt}
                      loading="eager"
                      fetchPriority="high"
                      fill
                    />
                  }
                  alt={category.attributes?.shortcutImageAlt}
                  size={ShortcutImageSize.LG}
                  href={`/${category.attributes.classificationOption?.data.attributes.slug}/`}
                  target={LinkTarget._self}
                  onImageClick={() => {
                    router.push(
                      `/${category.attributes.classificationOption?.data.attributes.slug}/`,
                    );
                    window.dataLayer.push({
                      event: 'genericEvent',
                      event_name: 'click_home_category',
                      event_parameter_1: category.attributes.name,
                    });
                  }}
                >
                  {category.attributes.name}
                </FsShortcutImage>
              </S.ShortcutImageWrapper>
            );
          }
        })}
        {isMobile ? (
          <Link href="/produtos/">
            <ShortcutIcon icon="PlusLine" label="Ver todos" />
          </Link>
        ) : (
          <S.ShortcutImageWrapper className="shortcut-image-wrapper">
            <Link href="/produtos/">
              <ShortcutIcon icon="PlusLine" label="Ver todos" />
            </Link>
          </S.ShortcutImageWrapper>
        )}
      </S.Categories>
    </div>
  );
}
