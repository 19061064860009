import { media } from '@mf/common/utils/styles/index';
import { FsBanner, FsBannerItem } from '@printi/ds-offset-react-core';
import styled from 'styled-components';

export const Banner = styled.div`
  width: 100%;
  > div {
    ${media.lessThan('mobile')} {
      display: flex;
      gap: var(--spacing-2x);
    }
  }
  .banner-item-3 {
    ${media.lessThan('mobile')} {
      width: 100%;
      height: 480px;
      > :first-child {
        width: 100%;
        > video {
          width: 80%;
          margin: 0 auto;
        }
      }
    }
    .banner-item-3 {
      ${media.lessThan('mobile')} {
        width: 100%;
        height: 480px;
        :first-child {
          width: 100%;
        }
      }
      background-color: #000;
    }

    .banner-item-1,
    .banner-item-2 {
      ${media.lessThan('mobile')} {
        height: 480px;
      }
    }

    .banner-item-1 {
      img {
        width: auto !important;
        height: auto !important;
        margin-left: auto;
        margin-right: 56px;
        margin-top: 186px;
        object-fit: initial;
        ${media.lessThan('mobile')} {
          object-fit: contain;
          width: 135px !important;
          margin-right: auto;
        }
      }
    }
  }
`;
export const SmallHeading = styled.span`
  max-width: 310px;
`;
export const BigHeading = styled.p`
  display: flex;
  max-width: 380px;
  margin: 0;
`;
export const Video = styled.video`
  height: 100%;
  margin-left: auto;
  margin-right: 0;
  display: flex;
  align-items: center;
  ${media.lessThan('mobile')} {
    height: 112%;
  }
`;

export const BannerItemVideo = styled(FsBannerItem)`
  background-color: var(--elements-bg-color-01);
`;

export const Banners = styled(FsBanner)`
  ${media.lessThan('mobile')} {
    > div {
      gap: var(--spacing-2x);
    }
  }

  .banner-item-1 {
    > div {
    }

    img {
      object-fit: initial;
      width: 385px;
      height: auto;
      position: relative;
      top: calc(50% + 40px);
      left: 50%;
      transform: translate(-50%, -50%);

      ${media.lessThan('mobile')} {
        top: calc(50% + 10px);
        width: 215px;
      }
    }
  }

  .banner-item-1,
  .banner-item-2 {
    ${media.lessThan('mobile')} {
      max-height: 480px;
    }
  }

  .banner-item-2 {
    img {
      object-fit: fill !important;
      height: 40% !important;
      width: 50% !important;
    }

    :nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    :nth-child(2) {
      > div {
        align-items: flex-start;
      }
    }
  }
`;
