export function HomeLetteringSeparator() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="32"
      viewBox="0 0 13 32"
      fill="none"
    >
      <g>
        <circle cx="6" cy="26" r="6" fill="#CCCCCC" />
      </g>
      <defs>
        <clipPath>
          <rect
            width="12"
            height="32"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
