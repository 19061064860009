import React from 'react';
import * as S from './HomeLettering.styles';
import { HomeLetteringSeparator } from './HomeLetteringSeparator';

export function HomeLettering() {
  return (
    <S.Container>
      <S.Lettering>
        <p>
          {Array.from({ length: 5 }, (_, i) => (
            <React.Fragment key={i}>
              <span>a sua gráfica, sempre </span>
              <HomeLetteringSeparator />
            </React.Fragment>
          ))}
        </p>
      </S.Lettering>
    </S.Container>
  );
}
