import { FsHeading, FsParagraph } from '@printi/ds-offset-react-core';
import styled from 'styled-components';

const SEOSection = styled.section``;

const SEOHeading = styled(FsHeading)`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: var(--spacing-2x);
`;

const SEOParagraph = styled(FsParagraph)`
  color: #525252;
  font-size: 14px;
  line-height: 20px;
`;

type SEOProps = {
  title: string;
  description: string;
};

export function HomeV2SEO({ title, description }: SEOProps) {
  return (
    <SEOSection>
      <SEOHeading>{title}</SEOHeading>
      <SEOParagraph>{description}</SEOParagraph>
    </SEOSection>
  );
}
