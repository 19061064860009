'use client';

import React, { useRef, useState } from 'react';
import {
  ButtonSecondarySize,
  FsButtonSecondary,
  FsCardTestimony,
  FsTopSection,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import dynamic from 'next/dynamic';
import { useLayout } from '@mf/common/components/Media/Layout';
import useDynamicMargins from '@mf/common/utils/hooks/useDynamicMargins/index';
import { ReactPlayerProps } from 'react-player/lazy';
import Image from 'next/image';
import * as S from './HomeTestimonies.styles';

const ReactPlayer = dynamic<ReactPlayerProps>(
  () => import('react-player/lazy').then((module) => module.default),
  { ssr: false },
);

export function HomeTestimonies() {
  // TODO: get testimonies from CMS
  const [showMore, setShowMore] = useState(false);
  const [playVideo, setPlayVideo] = useState({ play: false, url: '' });
  const { isMobile } = useLayout();
  const testimoniesRef = useRef<HTMLDivElement>(null);

  const handleShowMore = () => {
    setShowMore((prevState) => !prevState);
  };

  const handlePlayVideo = (url: string) => {
    document.body.style.overflow = 'hidden';
    setPlayVideo({ play: true, url: url });
  };

  const onCloseHandler = () => {
    document.body.style.overflow = '';
    setPlayVideo({ ...playVideo, play: false });
  };

  const [testimoniesMarginRight, testimoniesMarginLeft] = useDynamicMargins(
    testimoniesRef,
    isMobile,
  );

  /** TODO: add skeleton during loading */
  return (
    <S.Container>
      <S.PreviewVideo
        showOverlay={true}
        isVisible={playVideo.play}
        onClick={onCloseHandler}
      >
        <div className="videoLoading">
          <ReactPlayer
            playing={playVideo.play}
            controls={true}
            url={playVideo.url}
            width={'100%'}
            height={'100%'}
          />
        </div>
      </S.PreviewVideo>
      <FsTopSection
        heading="O que nossos clientes dizem"
        headingProps={{ tag: HeadingTag.H2 }}
        showDescription={false}
        style={{
          paddingBottom: 'var(--spacing-8x)',
        }}
      />
      <S.Testimonies
        ref={testimoniesRef}
        style={{
          marginRight: `${testimoniesMarginRight}`,
          marginLeft: `${testimoniesMarginLeft}`,
        }}
      >
        <S.Column>
          <S.CardTestimonyWrapper>
            <FsCardTestimony
              name="Elizabeth Peão"
              category="Profissional"
              text="Assista ao depoimento da Elizabeth, uma designer e diretora de arte incrível, que usa e acredita no trabalho da Printi há 12 anos."
              office="Designer e Diretora de Arte freelancer"
              image={
                <Image
                  src="https://d1br4h274rc9sc.cloudfront.net/content/testimony04_625e284fdc.webp"
                  alt="Elizabeth Peão"
                  fill
                />
              }
              onClick={() =>
                handlePlayVideo('https://vimeo.com/1006591810/ff3de84fc1')
              }
              headingProps={{ tag: HeadingTag.H4 }}
            />
          </S.CardTestimonyWrapper>
        </S.Column>
        <S.Column>
          <S.CardTestimonyWrapper>
            <FsCardTestimony
              name="Flávia Cury"
              category="Profissional"
              text="A relação com a Printi vem de muitos anos, mas hoje nos tornamos um parceiro estratégico em tudo o que sua agência produz."
              office="Proprietária na Flávia Cury Marketing"
              image={
                <Image
                  src="https://d1br4h274rc9sc.cloudfront.net/content/testimony02_c9378ed42c.webp"
                  alt="Flávia Cury"
                  fill
                />
              }
              onClick={() =>
                handlePlayVideo('https://vimeo.com/1006591384/16774cff46')
              }
              headingProps={{ tag: HeadingTag.H4 }}
            />
          </S.CardTestimonyWrapper>
        </S.Column>
        {(showMore || isMobile) && (
          <>
            <S.Column>
              <S.CardTestimonyWrapper>
                <FsCardTestimony
                  name="Ivan Neto"
                  category="Empreendedor"
                  text="Ele ajuda milhares de pessoas com o sonho do concurso público, encontrou na Printi o parceiro perfeito para produzir suas apostilas."
                  office="Sócio na Brabo Concursos"
                  image={
                    <Image
                      src="https://d1br4h274rc9sc.cloudfront.net/content/testimony03_a8c39e8def.webp"
                      alt="Ivan Neto"
                      fill
                    />
                  }
                  onClick={() =>
                    handlePlayVideo('https://vimeo.com/1006592609/11144c184e')
                  }
                  headingProps={{ tag: HeadingTag.H4 }}
                />
              </S.CardTestimonyWrapper>
            </S.Column>
            <S.Column>
              <S.CardTestimonyWrapper>
                <FsCardTestimony
                  name="Edson Faria"
                  category="Corporativo"
                  text="Ele é responsável por garantir que os produtos que amamos da Panini sejam impressos em parceiros de confiança. "
                  office="Gerente Industrial na Panini"
                  image={
                    <Image
                      src="https://d1br4h274rc9sc.cloudfront.net/content/testimony01_05fb0a20b3.webp"
                      alt="Edson Faria"
                      fill
                    />
                  }
                  onClick={() =>
                    handlePlayVideo('https://vimeo.com/1006592171/702be102b7')
                  }
                  headingProps={{ tag: HeadingTag.H4 }}
                />
              </S.CardTestimonyWrapper>
            </S.Column>
            <S.Column>
              <S.CardTestimonyWrapper>
                <FsCardTestimony
                  name="Emilly Naranjo"
                  category="Profissional"
                  text="Designer e cliente há muitos da Printi, hoje ajuda outras mulheres com o desafio de empreender e viver do seu sonho"
                  office="Designer e fundadora da CEO Sem Glamour"
                  image={
                    <Image
                      src="https://d1br4h274rc9sc.cloudfront.net/content/testimony05_8676606f57.webp"
                      alt="Emilly Naranjo"
                      fill
                    />
                  }
                  onClick={() =>
                    handlePlayVideo('https://vimeo.com/1006590944/6a435b2fbe')
                  }
                  headingProps={{ tag: HeadingTag.H4 }}
                />
              </S.CardTestimonyWrapper>
            </S.Column>
            <S.Column>
              <S.CardTestimonyWrapper>
                <FsCardTestimony
                  name="Aramis Ruiz"
                  category="Empreendedor"
                  text="Criado em uma família de árbitros de futebol, criou uma empresa criativa de figurinhas que conta com a Printi como parceiro."
                  office="Proprietário na Amo Figurinhas"
                  image={
                    <Image
                      src="https://d1br4h274rc9sc.cloudfront.net/content/testimony06_d8df88489a.webp"
                      alt="Aramis Ruiz"
                      fill
                    />
                  }
                  onClick={() =>
                    handlePlayVideo('https://vimeo.com/1006590651/8c2fae1323')
                  }
                  headingProps={{ tag: HeadingTag.H4 }}
                />
              </S.CardTestimonyWrapper>
            </S.Column>
          </>
        )}
      </S.Testimonies>

      {!isMobile && (
        <S.CTAContainer>
          <FsButtonSecondary
            size={ButtonSecondarySize.LG}
            onClick={handleShowMore}
          >
            {showMore ? 'Carregar menos' : 'Carregar mais'}
          </FsButtonSecondary>
        </S.CTAContainer>
      )}
    </S.Container>
  );
}
